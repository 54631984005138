.card {
  display: flex;
  flex-direction: column;
  color: #111111;
  border-bottom: 1px solid #D2D2D280;
  padding: 8px 0;

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;

    &__userInfo {
      width: 70%;
      display: flex;
      align-items: center;
      
      img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        margin-right: 8px;
      }

      p {
        font-size: 20px;
        font-weight: 600;
      }
    }

    &__date {
      width: 150px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &__comment {
      width: 70%;
      max-width: 400px;
      font-size: 16px;
      font-weight: 400;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    &__sum {
      width: 150px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.cardMobile {
  display: none;
}

@media (max-width: 767px) {
  .card {
    display: none;

    &_top {
      display: flex;
      flex-direction: column;

      &__userInfo {
        width: 100%;
      }

      &__date {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &_bottom {
      display: flex;
      justify-content: space-between;
      gap: 20px;
  
      &__comment {
        width: 100%;
        max-width: 300px;
      }
    }
  }

  .cardMobile {
    display: flex;
    flex-direction: column;
    color: #111111;
    border-bottom: 1px solid #D2D2D280;
    padding: 8px 4px;
  }
}