.FormBlock {
    position: sticky;
    top: 100px;
    margin-top: 147px;
    width: 100%;
    height: 657px;
    background: linear-gradient(302deg, rgba(255, 255, 255, 0.03) 3.11%, rgba(255, 255, 255, 0.01) 100%);
    backdrop-filter: blur(17.5px);
    border-radius: 30px;

    .Blur {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #e5e7f7;
        border-radius: 20px;
        opacity: 0.6;
    }

    .Stages {
        position: absolute;
        top: -27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 70px;
        &__Stage {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #A9B0C2;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: center;

            span {
                display: block;
                width: 54px;
                height: 54px;
                border-radius: 100%;
                background-color: #fff;
                font-family: Inter;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 54px;
                margin-bottom: 18px;
            }

            &Active {
                color: #111;
                span {
                    color: #FF2A23;
                }
            }
        }
    }

    .Form {
        position: absolute;
        width: 100%;
        height: calc(100% - 137px);
        bottom: 0;
        padding: 0 70px 70px;

        .PaymentTypes {
            display: flex;
            width: 100%;
            height: 60px;
            background-color: #fff;
            border-radius: 100px;

            &__Type {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                border-radius: 100px;
                width: 50%;
                cursor: pointer;

                span {
                    color: #111;
                    font-family: Inter;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }

                &Active {
                    background-color: #FF2A23;
                    span {
                        color: #fff;
                    }
                }
            }
        }

        .PaymentInfo {
            display: flex;
            align-items: start;
            margin-top: 20px;
            color: #111;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .DonationSums {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;

            &__Sum {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-basis: 24%;
                height: 60px;
                border-radius: 100px;
                background-color: #fff;
                cursor: pointer;

                span {
                    color: #111;
                    font-family: Inter;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }

                &Active {
                    outline: 1px solid #FF2A23;

                    span {
                        color: #FF2A23;
                    }
                }
            }
        }

        .DonationInputSum {
            margin-top: 30px;

            &__Input {
                width: 100%;
                background-color: #fff;
                outline: none;
                border: none;
                border-radius: 100px;
                padding: 18px 30px 12px;
                color: #111;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }

            &__Text {
                margin-top: 10px;
                color: #111;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                opacity: 0.5;
            }
        }

        .DonationButton {
            position: absolute;
            bottom: 70px;
            width: calc(100% - 140px);
            display: flex;
            justify-content: end;

            &__Back {
                display: flex;
                align-items: center;
                cursor: pointer;
                
                img {
                    margin-right: 8px;
                }

                color: #A9B0C2;
                font-family: Inter;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }

            button {
                width: 50%;
                height: 60px;
                padding: 10px 30px;
                border: none;
                outline: none;
                border-radius: 100px;
                background-color: #FF2A23;
                color: #fff;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            &WithBack {
                justify-content: space-between;
            }
        }

        // .PaymentService {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     width: 100%;
        //     height: 60px;
        //     background-color: #fff;
        //     border-radius: 100px;
        //     padding: 0 30px;

        //     &__type {
        //         display: flex;
        //         align-items: center;
        //         color: #111;
        //         font-family: Inter;
        //         font-size: 18px;
        //         font-style: normal;
        //         font-weight: 400;
        //         line-height: 24px;

        //         img {
        //             margin-right: 12px;
        //         }
        //     }

        //     &__logos {
        //         display: flex;
        //         justify-content: space-between;
        //         align-items: center;
        //         width: 150px;
        //     }
        // }

        .PaymentService {
            position: relative;
            width: 100%;
        }
        
        .PaymentService__selected {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;
            background-color: #fff;
            border-radius: 100px;
            padding: 0 30px;
            cursor: pointer;
            border: 1px solid #ccc;
            z-index: 5;
        }
        
        .PaymentService__type {
            display: flex;
            align-items: center;
            color: #111;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        
            img {
                width: 36px;
                margin-right: 12px;
            }
        }
        
        .PaymentService__logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 150px;
            margin-left: 140px;
        }
        
        .PaymentService__arrow {
            width: 20px;
            height: 20px;
            transform: rotate(180deg);
            transition: transform 0.2s ease;
        }
        
        .rotate {
            transform: rotate(0deg);
        }
        
        .PaymentService__dropdown {
            position: absolute;
            top: 20px;
            padding: 48px 0 0;
            left: 2px;
            width: calc(100% - 3px);
            height: 152px;
            background-color: #fff;
            box-shadow: -5.88px 13.14px 13px 0px #3A41540E;
            border-radius: 40px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            overflow-y: auto;
            z-index: 3;

            &Once {
                height: 200px;
            }
        }
        
        .PaymentService__option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 30px;
            cursor: pointer;
        
            &:hover {
                background-color: #f0f0f0;
            }

            &:last-child {
                border-bottom-right-radius: 40px;
                border-bottom-left-radius: 40px;
            }
        }

        .Comment {
            position: relative;
            display: flex;
            flex-direction: column;

            label {
                color: #111;
                margin: 20px 0 8px 30px;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            textarea {
                height: 198px;
                border: none;
                outline: none;
                resize: none;
                border-radius: 30px;
                background-color: #fff;
                padding: 30px;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
            }

            span {
                position: absolute;
                bottom: 16px;
                right: 30px;
                color: #A9B0C2;
            }
        }

        .ContactBlock {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            row-gap: 30px;

            &__Input {
                display: flex;
                flex-direction: column;
                width: 100%;

                label {
                    color: #111;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    padding-left: 30px;

                    span {
                        color: #FF2A23;
                    }
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: #fff;
                    border-radius: 100px;
                    padding: 18px 30px 18px;
                    color: #111;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }

                input[disabled] {
                    color: #A9B0C2;
                }
            }

            &__Checkbox {
                display: flex;
                flex-direction: column;
                justify-content: end;

                div {
                    display: flex;
                }

                input {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }
                input+label {
                    user-select: none;
                }
                input+label::before {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border: 1px solid #111;
                    border-radius: 4px;
                    margin-right: 8px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 50% 50%;
                }
                input:checked+label::before {
                    background-image: url("../../new-images/donation/check.svg");
                    background-size: 70%;
                }

                p {
                    color: #A9B0C2;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    span {
                        color: #FF2A23;
                    }
                }
            }
        }

        .ErrorInput {
            outline: 1px solid #FF2A23 !important;
        }
        
        .ErrorText {
            color: #FF2A23 !important;
        }

        .ErrorCheckBox {
            &+label::before {
                border: 1px solid #FF2A23 !important;
            }
        }

        &Success {
            background-image: url('../../new-images/donation/bg-success.svg');
            background-repeat: no-repeat;
            background-position: bottom;

            h2 {
                color: #111;
                font-family: Inter;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 56px;
            }

            h3 {
                color: #111;
                font-family: Inter;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
            }
        }
    }
}

.HideInfoBlock {
    margin: 12px 0 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    row-gap: 5px;

    div {
        display: flex;
        align-items: center;
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    input+label {
        user-select: none;
        margin-bottom: 0;
    }
    input+label::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #111;
        border-radius: 4px;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    input:checked+label::before {
        background-image: url("../../new-images/donation/check.svg");
        background-size: 70%;
    }

    p {
        color: #A9B0C2;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 2px;
        span {
            color: #FF2A23;
        }
    }
}

@media (max-width: 1919px) {
    .FormBlock  {
        top: 100px;
        margin-top: 158px;
        width: 640px;
        height: 565px;

        .Stages {
            top: -39px;
            padding: 0 55px;
            &__Stage {
                span {
                    width: 42px;
                    height: 42px;
                    font-size: 32px;
                    line-height: 42px;
                    margin-top: 16px;
                }
            }
        }

        .Form {
            height: calc(100% - 100px);
            padding: 0 55px 55px;

            .PaymentTypes {
                height: 48px;
                &__Type {
                    span {
                        font-size: 18px;
                    }
                }
            }

            .PaymentService__logos {
                margin-left: 50px;
            }
            
            .PaymentInfo {
                margin-top: 10px;
                font-size: 12px;
                line-height: 14px;
            }

            .DonationSums {
                margin-top: 20px;
                &__Sum {
                    height: 48px;
                    span {
                        font-size: 18px;
                    }
                }
            }
            
            .DonationInputSum {
                &__Input {
                    padding: 14px 24px 10px;
                    font-size: 18px;
                    line-height: 24px;
                }

                &__Text {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            
            .DonationButton {
                bottom: 55px;
                width: calc(100% - 112px);
                button {
                    width: 55%;
                    height: 48px;
                    padding: 8px 24px;
                }
            }

            .PaymentService {
                height: 48px;
            }

            .Comment {
                label {
                    margin: 20px 0 8px 20px; 
                }

                textarea {
                    height: 180px;
                    padding: 16px;
                    font-size: 16px;
                }

                span {
                    right: 20px;
                }
            }

            .ContactBlock {
                &__Input {
                    input {
                        padding: 12px 30px 12px;
                    }
                }
            }

            &Success {
                background-size: 80%;
                h2 {
                    font-size: 32px;
                    line-height: 48px;
                }

                h3 {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .FormBlock  {
        top: 80px;
        margin-top: 114px;
    }
}

@media (max-width: 1199px) {
    .FormBlock  {
        width: 100%;
        top: 0;
        margin-top: 36px;

        .Form {
            padding: 10px 87px 55px;

            .DonationButton {
                bottom: 55px;
                width: calc(100% - 175px);
                button {
                    width: 55%;
                    height: 48px;
                    padding: 8px 24px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .FormBlock  {
        width: 100%;
        top: 0;
        margin-top: 36px;

        &OpenWarn {
            height: 600px;
        }

        .Stages {
            padding: 0 20px;

            &__Stage {
                font-size: 14px;
                
                span {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;
                    font-size: 24px;
                }
            }
        }

        .Form {
            height: 495px;
            padding: 10px 20px 30px;

            &OpenWarn {
                top: 70px;
                height: 530px;
            }

            .PaymentTypes {
                height: 60px;
                &__Type {
                    span {
                        font-size: 14px;
                    }
                }
            }

            .PaymentService {
                padding: 0 !important;
            }

            .PaymentService__logos {
                display: none;
            }

            .PaymentService__dropdown {
                height: 124px;
    
                &Once {
                    height: 162px;
                }
            }

            .DonationSums {
                flex-wrap: wrap;
                margin-top: 10px;

                &__Sum {
                    flex-basis: 48%;
                    height: 60px;
                    margin-top: 10px;

                    span {
                        font-size: 14px;
                    }
                }
            }

            .DonationInputSum {
                &__Input {
                    padding: 21px 30px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .DonationButton {
                bottom: 30px;
                width: calc(100% - 40px);

                &__Back {
                    margin-right: 40px;
                    span {
                        display: none;
                    }
                }

                button {
                    width: 100%;
                    height: 48px;
                    padding: 8px 16px;
                    font-size: 14px;
                }
            }

            .PaymentService {
                height: 60px;
                padding: 0 20px;
                &__type {
                    img {
                        width: 22px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    p {
                        color: #111;
                        font-size: 13px;
                        line-height: 18px;
                    }
                }

                &__logos {
                    width: 75px;
                    img {
                        width: 23px;
                        height: 17px;
                    }
                }
            }

            .Comment {
                label {
                    font-size: 14px;
                }

                textarea {
                    font-size: 14px;
                    line-height: 18px;
                }

                span {
                    font-size: 12px;
                }
            }

            .ContactBlock {
                grid-template-columns: 1fr;
                row-gap: 12px;

                &__Input {

                    label {
                        font-size: 14px;
                        line-height: 16px;
                    }

                    input {
                        font-size: 14px;
                        padding: 18px 30px 18px;
                    }
                }

                &__Checkbox {
                    label {
                        margin-bottom: 4px;
                    }

                    p {
                        font-size: 9.2px;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .smallBlock {
        height: 300px;
        .FormSuccess {
            height: 100%;

            h2 {
                margin-top: 70px;
                line-height: 40px;
            }
        }
    }

    .HideInfoBlock {
        margin: 12px 0 0 0;

        &_mobile {
            margin-top: 14px;

            br {
                display: none;
            }
        }

        p {
            font-size: 9.2px;
            line-height: 14px;
            margin-bottom: 4px;
        }
    }
}